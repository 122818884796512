<template>
  <div id="map">
    <l-map
      v-if="loaded"
      ref="map"
      :zoom="zoom"
      :center="center"
      :options="{ minZoom: 3, maxZoom: 18 }"
      style="height: 700px; width: 100%; max-height: 100%; z-index: 4"
      @ready="updateLayout"
    >
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-control>
        <v-card v-if="displayLegend" max-width="200">
          <v-card-title>
            {{ $t("map.livemap.legend.title") }}
            <v-spacer />
            <v-btn icon @click="() => (displayLegend = false)">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p>{{ $t("map.livemap.legend.description.1") }}</p>
            <p>{{ $t("map.livemap.legend.description.2") }}</p>
            <p>{{ $t("map.livemap.legend.description.3") }}</p>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-btn icon @click="() => (displayLegend = true)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-card>
      </l-control>

      <v-marker-cluster :options="clusterOptions">
        <l-marker
          v-for="(item, i) in tagsWithCoordinates"
          :key="item.deveui + i"
          :lat-lng="item.latLng"
          :icon="getIcon(item.attributeDict.RSSI, item.attributeDict.SNR)"
          :options="{
            value: getIconColorValue(
              item.attributeDict.RSSI,
              item.attributeDict.SNR
            ),
          }"
        >
          <l-popup>
            <h3>
              {{ $t("tag.fields.deveui") }}
            </h3>
            <p class="headline-3">{{ item.deveui }}</p>

            <h3>
              {{ $t("signalTest.fields.data.rssi") }}
            </h3>
            <p class="headline-3">{{ item.attributeDict.RSSI ?? "N/A" }}</p>

            <h3 class="headline-2">
              {{ $t("signalTest.fields.data.snr") }}
            </h3>
            <p class="headline-3">{{ item.attributeDict.SNR ?? "N/A" }}</p>

            <h3 class="headline-2">
              {{ $t("signalTest.fields.data.lastGateway") }}
            </h3>
            <p class="headline-3">
              {{ item.attributeDict.GATEWAY ?? "N/A" }}
            </p>

            <h3 class="headline-2">
              {{ $t("signalTest.fields.data.createdAt") }}
            </h3>
            <p class="headline-3">
              {{ humanDate(item.lastContact) }}
            </p>
          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as L from "leaflet/dist/leaflet-src.esm";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup, LControl } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

const colorvalue = {
  NO_SIGNAL: 0,
  GOOD: 1,
  AVERAGE: 2,
  BAD: 3,
};

export default {
  name: "LiveMap",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    LControl,
  },

  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      loaded: false,
      defaultCenter: null,
      defaultZoom: 12,
      center: null,
      zoom: null,
      displayLegend: false,

      iconSize: 30,
      iconColorGood: "#64de64", // Green
      iconColorAvg: "#fffc38", // Yellow
      iconColorBad: "#fa4d4d", // Red
      iconColorNoSignal: "#8f8f8f", // Gray
      clusterOptions: {
        iconCreateFunction: (cluster) => {
          return this.clusterIconCreateFunction(cluster);
        },
      },
    };
  },

  computed: {
    ...mapGetters("configuration", ["getCompanyCoordinates"]),

    tagsWithCoordinates() {
      return this.tags
        .filter((x) => {
          return x.latitude != null && x.longitude != null;
        })
        .map((x) => ({
          ...x,
          latLng: L.latLng(x.latitude, x.longitude),
        }));
    },
  },

  methods: {
    clusterIconCreateFunction(cluster) {
      // Calculates the worst color among the markers in the cluster
      let worstColor = cluster
        .getAllChildMarkers()
        .reduce((acc, loc) =>
          Number(acc.options.value) > Number(loc.options.value) ? acc : loc
        ).options.value;

      return new L.DivIcon({
        html: `<div style="background-color:${this.getIconColor(
          Number(worstColor)
        )}; font-weight: 900;opacity: 0.8;"><span>${cluster.getChildCount()}</span></div>`,
        className: "marker-cluster",
        iconSize: new L.Point(this.iconSize, this.iconSize),
      });
    },

    getIcon(rssi, snr) {
      return new L.DivIcon({
        html: `<div style="background-color:${this.getIconColorCalc(
          rssi,
          snr
        )}; border:0.01px solid black;"><span>${rssi ?? "N/A"}</span></div>`,
        className: "marker-cluster",
        iconSize: new L.Point(this.iconSize, this.iconSize),
      });
    },

    getIconColorCalc(rssi, snr) {
      return this.getIconColor(this.getIconColorValue(rssi, snr));
    },

    getIconColor(value) {
      switch (value) {
        case colorvalue.NO_SIGNAL:
          return this.iconColorNoSignal;
        case colorvalue.GOOD:
          return this.iconColorGood;
        case colorvalue.AVERAGE:
          return this.iconColorAvg;
        case colorvalue.BAD:
          return this.iconColorBad;
        default:
          return this.iconColorNoSignal;
      }
    },

    getIconColorValue(rssi, snr) {
      return Math.max(this.getRssiColorValue(rssi), this.getSnrColorValue(snr));
    },

    getRssiColorValue(rssi) {
      if (rssi === null || rssi === undefined) {
        return colorvalue.NO_SIGNAL;
      } else if (rssi > -115) {
        return colorvalue.GOOD;
      } else if (rssi <= -120) {
        return colorvalue.BAD;
      } else {
        return colorvalue.AVERAGE;
      }
    },

    getSnrColorValue(snr) {
      if (snr === null || snr === undefined) {
        return colorvalue.NO_SIGNAL;
      } else if (snr > 0) {
        return colorvalue.GOOD;
      } else if (snr <= -10) {
        return colorvalue.BAD;
      } else {
        return colorvalue.AVERAGE;
      }
    },

    async updateLayout() {
      this.$nextTick(() => {
        this.$refs.map.mapObject.invalidateSize();
        this.fitToTags();
      });
    },

    fitToTags() {
      let boundsDict = L.latLngBounds(
        this.tagsWithCoordinates.map((x) => [x.latLng.lat, x.latLng.lng])
      );
      let boundsArray = Object.values(boundsDict).map((x) => Object.values(x));

      this.$nextTick(() => {
        if (boundsArray.length > 0) {
          this.$refs.map.mapObject.fitBounds(boundsArray, {
            padding: [10, 10],
          });
          return;
        }
        this.$refs.map.mapObject.setView(this.defaultCenter, this.defaultZoom);
      });
    },
  },

  mounted() {
    this.center = this.defaultCenter;
    this.zoom = this.defaultZoom;
    this.loaded = true;
  },

  created() {
    this.defaultCenter = this.getCompanyCoordinates;
    this.defaultCenter =
      this.defaultCenter.latitude != null &&
      this.defaultCenter.longitude != null
        ? (this.defaultCenter = L.latLng(
            this.defaultCenter.latitude,
            this.defaultCenter.longitude
          ))
        : L.latLng(58.38999888912673, 13.857677403817654);
  },

  watch: {
    tags: {
      handler() {
        this.fitToTags();
      },
    },
  },
};
</script>

<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.leaflet-div-icon {
  background: transparent !important;
  border: transparent !important;
}

.leaflet-popup-content p {
  margin: 4px 0px;
}
</style>
